import React from 'react'
import Icon from './Icon'
import Img from 'gatsby-image'
import styles from './photo_preview.module.scss'

class PhotoPreview extends React.Component {
  state = {
    currIdx: 0,
  }

  onClickPrev = () => {
    const { currIdx } = this.state
    if (currIdx > 0) {
      this.setState({
        currIdx: currIdx - 1,
      })
    }
  }

  onClickNext = () => {
    const { currIdx } = this.state
    const { idList } = this.props
    if (this.state.currIdx < idList.length - 1) {
      this.setState({
        currIdx: currIdx + 1,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currIdx: nextProps.id,
    })
  }

  render() {
    const { currIdx } = this.state
    const { idList, data, onClose, status } = this.props
    const imageId = idList[currIdx]
    const imgData = data[imageId] || null

    return (
      <div className={`${styles['photo-preview']} ${status ? '' : styles['hide']}`}>
        <Icon
          icon='left'
          className={`${styles['icon-left']} ${currIdx === 0 ? styles['hide'] : ''}`}
          onClick={this.onClickPrev}
        />
        <Icon
          icon='right'
          className={`${styles['icon-right']} ${
            currIdx === idList.length - 1 ? styles['hide'] : ''
          }`}
          onClick={this.onClickNext}
        />
        <Icon
          icon='close'
          className={styles['icon-close']}
          onClick={onClose}
        />
        {imgData ? (
          <div className={styles['photo-preview-content']}>
            <Img style={{ position: 'static' }} fluid={imgData.childImageSharp.fluid} alt='photoPreview' />
          </div>
        ) : null}
      </div>
    )
  }
}

export default PhotoPreview
