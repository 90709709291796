import React from 'react'
import styles from './partner.module.scss'
// import { isMobile } from 'src/helpers/tool.js'

// const timer = 80000
const speed = 105 // px/s

class ImgInfinite extends React.Component {
  start = null

  constructor(props) {
    super(props)
    this.state = {
      imgLength: 0,
    }
    this.imgs = React.createRef()
  }

  componentDidMount() {
    this.setState({
      imgLength: 5632, // px
    })
    let ele = this.imgs.current
    // handle scroll once on component mount, in case that page is refreshed on partner section
    // this.handleScroll(ele)
    window.addEventListener('scroll', () => {
      this.handleScroll(ele)
    })
  }

  componentWillUnmount() {
    this.timeOut && clearTimeout(this.timeOut)
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = ele => {
    if (this.timeOut) {
      return
    }
    const parentEle = ele.parentElement
    const offsetTop = parentEle.offsetTop
    const scrollTop = window.pageYOffset
    if (scrollTop + ele.offsetHeight > offsetTop) {
      this.timeOut = setTimeout(() => {
        this.imgAnimation(ele)
      }, 1000)
    }
  }

  imgAnimation = ele => {
    const { imgLength } = this.state
    let progress = 0
    if (!this.start) {
      this.start = new Date()
    } else {
      progress = new Date() - this.start
    }

    const left = (progress * speed) / 1000
    ele.setAttribute('style', `left: -${left}px`)

    if (progress > (imgLength / speed) * 1000) {
      this.start = null
      ele.setAttribute('style', `left: 0`)
    }

    window.requestAnimationFrame(() => {
      this.imgAnimation(ele)
    })
  }

  render() {
    const { styleWhite } = this.props

    return (
      <div className={styles['tech-slideshow']}>
        <div className={styles['mover']} ref={this.imgs}>
          {/* <div className={styles['partner-img1']}></div>
          <div className={styles['partner-img2']}></div>
          <div className={styles['partner-img1']}></div>
          <div className={styles['partner-img2']}></div> */}
          <div className={styles['partner-img']}></div>
          <div className={styles['partner-img']}></div>
        </div>
        <div
          className={`${styles['left-flur']} ${
            styleWhite ? styles['left-white'] : styles['left-blue']
          }`}
        ></div>
        <div
          className={`${styles['right-flur']} ${
            styleWhite ? styles['right-white'] : styles['right-blue']
          }`}
        ></div>
      </div>
    )
  }
}

const Partner = ({ styleWhite }) => {
  return (
    <section className={styles['partner-section']}>
      <div className={styles['wrapper']}>
        <ImgInfinite styleWhite={styleWhite} />
      </div>
    </section>
  )
}

export default Partner
