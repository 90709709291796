import React from 'react'
import Img from 'gatsby-image'
import styles from './benefits_img.module.scss'

const BenefitsImg = ({ img, desc }) => {
  return (
    <div className={styles['benefits-img']}>
        <Img fluid={img} alt='benefits' />
        <div className={styles['benefits-desc']}>{desc}</div>
    </div>
  )
}

export default BenefitsImg
