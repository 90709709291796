import React, { useState }from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BenefitsImg from 'src/components/BenefitsImg.js'
import PhotoPreview from 'src/components/PhotoPreview.js'
import styles from './benefits.module.scss'

const Benefits = () => {
    const data = useStaticQuery(graphql`
        query {
            benefits1: file(relativePath: { eq: "benefits/benefits_1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 384, maxHeight: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            benefits2: file(relativePath: { eq: "benefits/benefits_2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 384, maxHeight: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            benefits3: file(relativePath: { eq: "benefits/benefits_3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 384, maxHeight: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            festival1: file(relativePath: { eq: "benefits/festival_1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            festival2: file(relativePath: { eq: "benefits/festival_2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            festival3: file(relativePath: { eq: "benefits/festival_3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            festival4: file(relativePath: { eq: "benefits/festival_4.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            festival5: file(relativePath: { eq: "benefits/festival_5.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            train1: file(relativePath: { eq: "benefits/train_1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            train2: file(relativePath: { eq: "benefits/train_2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            train3: file(relativePath: { eq: "benefits/train_3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            care1: file(relativePath: { eq: "benefits/care_1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            care2: file(relativePath: { eq: "benefits/care_2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const benefitsImgs = [
        [
          'festival1',
          'festival2',
          'festival3',
          'festival4',
          'festival5',
        ],
        [
          'train1',
          'train2',
          'train3',
        ],
        [
          'care1',
          'care2',
        ],
      ]
    const [onPreview, setOnPreview] = useState(false)
    const [id, setId] = useState(0)
    const [ids, setIds] = useState([])
    
    const onClose = () => {
        setOnPreview(false)
    }


    const onClickBenefits = (idx, e) => {
        setOnPreview(true)
        setId(0)
        setIds(benefitsImgs[idx])
    }

    return (
        <div className={styles['benefits-section']}>
            <div className={styles['bene-img']} onClick={e => onClickBenefits(0)}>
                <BenefitsImg img={data.benefits1.childImageSharp.fluid} desc='节日福利' />
            </div>
            <div className={styles['bene-img']} onClick={e => onClickBenefits(1)}>
                <BenefitsImg img={data.benefits2.childImageSharp.fluid} desc='专业培训' />
            </div>
            <div className={styles['bene-img']} onClick={e => onClickBenefits(2)}>
                <BenefitsImg img={data.benefits3.childImageSharp.fluid} desc='日常关怀' />
            </div>
            <PhotoPreview
                data={data}
                idList={ids}
                id={id}
                onClose={onClose}
                status={onPreview}
            />
        </div>
    )
}

export default Benefits
