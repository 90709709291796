import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './mobile.module.scss'

const TransporterMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      mobile: file(relativePath: { eq: "mobile_2_ms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Img fluid={data.mobile.childImageSharp.fluid} alt="mobile" />
      <a
        className={`${styles['btn']} ${styles['btn-download']}`}
        target="_blank"
        href="/redirect?app=dada"
      >
        下载达达秒送骑士APP
      </a>
    </>
  )
}

export default TransporterMobile
