import React from 'react'
import Img from 'gatsby-image'
import styles from './advantage.module.scss'

const Advantage = ({ img, title, desc }) => (
  <div className={styles['service-box']}>
    <div className={styles['service-img']}>
      <Img fluid={img} alt='advantage' />
    </div>
    <div className={styles['service-title']}>{title}</div>
    <div className={styles['service-desc']}>{desc}</div>
  </div>
)

export default Advantage
