import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styles from './header_img.module.scss'

/**
 * img 图片
 * title 图片
 * desc 文字
 * button 文字
 * qrcode 图片
 */
export default props => {
  const data = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "header_img/home_2_ms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enterprise: file(relativePath: { eq: "header_img/enterprise_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transporter: file(relativePath: { eq: "header_img/transporterNew_1_ms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zhipei: file(relativePath: { eq: "header_img/zhipei.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [showQrcode, setShowQrcode] = useState(false)

  const handleClick = () => {
    if (props.link) {
      window.open(props.link)
    }
  }

  const handleMouseDown = () => {
    if (props.qrcode) {
      showQrcode ? setShowQrcode(false) : setShowQrcode(true)
    }
  }

  const handleMouseLeave = () => {
    if (props.qrcode) {
      setShowQrcode(false)
    }
  }

  return (
    <div className={styles['header-img-wrapper']}>
      <Img
        style={{ height: '37.5rem' }}
        fluid={data[props.img].childImageSharp.fluid}
        alt='headerImg'
      />

      <div className={styles['header-img']}>
        {props.title && <img className={`${styles['title']} ${props.img === 'home' ? styles['home-title'] : ''}`} src={props.title} alt='headerTitle' />}

        {props.children ? (
          props.children
        ) : (
          <div className={styles['desc']}>{props.desc}</div>
        )}

        {
          props.button && <div
            className={`${styles['button']} ${showQrcode ? styles['button-active'] : ''}`}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
          >
            {props.button}
            {showQrcode ? (
              <img
                className={styles['qrcode']}
                src={props.qrcode}
                alt='qrcode'
              />
            ) : (
              ''
            )}
          </div>
        }
      </div>
    </div>
  )
}
