import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import SectionTitle from 'src/components/SectionTitle'
import Partner from 'src/components/Partner'
import Advantage from 'src/components/Advantage'
import Product from 'src/components/Product'
import HeaderImg from 'src/components/HeaderImg'
import TransporterMobile from 'src/components/TransporterMobile'
import Step from 'src/components/Step'
import Benefits from 'src/components/Benefits'
import styles from './transporter.module.scss'

const TransporterPage = () => {
  const data = useStaticQuery(graphql`
    query {
      timeImg: file(relativePath: { eq: "platform_advantage/time_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 96, maxHeight: 96) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      incomeImg: file(relativePath: { eq: "platform_advantage/income_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 96, maxHeight: 96) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      receiptImg: file(relativePath: { eq: "platform_advantage/receipt_ms.png" }) {
        childImageSharp {
          fluid(maxWidth: 96, maxHeight: 96) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      orderAdequateImg: file(
        relativePath: { eq: "platform_advantage/order_adequate_ms.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 96, maxHeight: 96) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const app = [
    {
      title: '达达秒送骑士APP',
      desc: '空闲时的赚钱利器',
      btns: [
        {
          id: 'dada-transporter-ios',
          icon: 'iOS',
          text: 'iOS版',
          img: 'dada_transporter_app_ms',
        },
        {
          id: 'dada-transporter-android',
          icon: 'android',
          text: 'Android版本',
          img: 'dada_transporter_app_ms',
        },
      ],
    },
    {
      title: '达达秒送APP',
      desc: '解决你的所有配送需求',
      btns: [
        {
          id: 'dada-ios',
          icon: 'iOS',
          text: 'iOS版',
          img: 'dada_app_ms',
        },
        {
          id: 'dada-android',
          icon: 'android',
          text: 'Android版本',
          img: 'dada_app_ms',
        },
        {
          id: 'dada-mp',
          icon: 'wechatmp',
          text: '微信小程序',
          img: 'dada_mp_ms',
        },
        {
          id: 'dada-wechat',
          icon: 'wechat',
          text: '微信公众号',
          img: 'dada_wechat_ms',
        },
      ],
    },
    {
      title: '达达秒送骑士家园',
      desc: '关注达达秒送骑士的赚钱技巧和生活分享都在这里',
      btns: [
        {
          id: 'dada-home-douyin',
          icon: 'douyin',
          text: '抖音号',
          img: 'dada_douyin_ms',
        },
        {
          id: 'dada-home-wechat',
          icon: 'wechat',
          text: '微信公众号',
          img: 'dada_home_wechat_ms',
        },
      ],
      douyin: '请使用抖音 APP 扫描该二维码',
    },
  ]

  const [showQrcode, setShowQrcode] = useState(false)

  const handleMouseDown = () => {
    showQrcode ? setShowQrcode(false) : setShowQrcode(true)
  }

  const handleMouseLeave = () => {
    setShowQrcode(false)
  }

  return (
    <Layout mobile={<TransporterMobile />}>
      <SEO title="成为达达秒送骑士" />
      <div className={styles['transporter-page']}>
        <HeaderImg img="transporter">
          <div
            className={styles['download-app']}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
          >
            下载客户端
            {showQrcode ? (
              <img
                className={styles['qrcode']}
                src={require('../images/header_img/qrcode_knight_ms.png')}
                alt='qrcode'
              />
            ) : (
              ''
            )}
          </div>
        </HeaderImg>
        <div className={styles['platform-advatage']}>
          <div className={styles['title']}>
            <SectionTitle title="平台优势" engTitle="PLATFORM" />
          </div>
          <div className={styles['row1']}>
            <Advantage
              img={data.timeImg.childImageSharp.fluid}
              title={'时间自由'}
              desc={'工作时间、工作区域，自主调配不受限制。体面的自由工作。'}
            />
            <Advantage
              img={data.incomeImg.childImageSharp.fluid}
              title={'收入丰厚'}
              desc={'按单结算，多劳多得，可获得丰厚的收入。提现方便快捷。'}
            />
          </div>
          <div className={styles['row2']}>
            <Advantage
              img={data.receiptImg.childImageSharp.fluid}
              title={'接单便捷'}
              desc={'手机APP接单，配送流程简单，操作便捷。赚钱更加高效。'}
            />
            <Advantage
              img={data.orderAdequateImg.childImageSharp.fluid}
              title={'单量充足'}
              desc={'数千万个人用户为你提供源源不断的高价订单。'}
            />
          </div>
        </div>
        <Step
          title={'成为达达秒送骑士'}
          engTitle={'RIDER'}
          stepDesc={['下载达达秒送骑士APP', '提交个人资料', '完成培训，开始赚钱']}
        />
        <div className={styles['benefits']}>
          <SectionTitle title="平台福利" engTitle="RIDER" />
          <Benefits />
        </div>
        <div className={styles['partner']}>
          <SectionTitle title="合作伙伴" engTitle="PARTNER" />
          <Partner styleWhite={false} />
        </div>
        <Product app={app} phone="transporter_phone" />
      </div>
    </Layout>
  )
}

export default TransporterPage
