import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from 'src/components/SectionTitle.js'
import Icon from 'src/components/Icon'
import styles from './product.module.scss'

export default props => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "product/bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 460, maxHeight: 460) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home_phone: file(relativePath: { eq: "product/home_phone_ms_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, maxHeight: 666) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enterprise_phone: file(
        relativePath: { eq: "product/home_phone_ms_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, maxHeight: 666) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transporter_phone: file(
        relativePath: { eq: "product/home_phone_ms_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 564, maxHeight: 666) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [current, setCurrent] = useState('')

  return (
    <div className={styles['product-wrapper']}>
      <div className={styles['product']}>
        <SectionTitle title="我们的产品" engTitle="PRODUCT" colorStyle={true} />
        <div className={styles['content']}>
          {props.app.map((item, idx) => {
            return (
              <div className={styles['app']} key={idx}>
                <div className={styles['title']}>{item.title}</div>
                <div className={styles['desc']}>{item.desc}</div>
                <div className={styles['buttons']}>
                  {item.btns.map((btn, idxBtn) => {
                    return (
                      <div
                        key={idxBtn}
                        className={`${styles['button']} ${current === btn.id ? styles['button-active'] : ''}`}
                        onMouseDown={() =>
                          current ? setCurrent('') : setCurrent(btn.id)
                        }
                        onMouseLeave={() => setCurrent('')}
                      >
                        <Icon className={styles['icon']} icon={btn.icon}></Icon>{' '}
                        {btn.text}
                        {current === btn.id ? (
                          <img
                            className={styles['qrcode']}
                            src={require(`../images/product/${btn.img}.png`)}
                            alt='productQrcode'
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    )
                  })}
                </div>
                {item.douyin && <div className={styles['douyin']}>{item.douyin}</div>}
              </div>
            )
          })}
        </div>
        <Img
          className={styles['phone']}
          fluid={data[props.phone].childImageSharp.fluid}
          style={{
            position: 'absolute',
            top: '19.8125rem',
            right: '-1.875rem',
          }}
          alt='phone'
        />
      </div>
    </div>
  )
}
