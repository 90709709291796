import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SectionTitle from 'src/components/SectionTitle.js'
import styles from './step.module.scss'

export default props => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg_ms.png" }) {
        childImageSharp {
          fixed(width: 314, height: 314) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      download: file(relativePath: { eq: "step/download_ms.png" }) {
        childImageSharp {
          fixed(width: 88, height: 88) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      write: file(relativePath: { eq: "step/write_ms.png" }) {
        childImageSharp {
          fixed(width: 88, height: 88) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      review: file(relativePath: { eq: "step/review_ms.png" }) {
        childImageSharp {
          fixed(width: 88, height: 88) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className={styles['step-wrapper']}>
      <div className={styles['step']}>
        <SectionTitle title={props.title} engTitle={props.engTitle} />
        <div className={styles['step-bar']}>
          <div className={styles['step-num-wrapper']}>
            <div className={styles['step-num']}>1</div>
            <div className={styles['step-num']}>2</div>
            <div className={styles['step-num']}>3</div>
          </div>
          <div className={styles['step-line']}></div>
        </div>
        <div className={styles['step-card']}>
          <div className={styles['card']}>
            <Img fixed={data.download.childImageSharp.fixed} alt='download' />
            <div className={styles['card-desc']}>{props.stepDesc[0]}</div>
          </div>
          <div className={styles['card']}>
            <Img fixed={data.write.childImageSharp.fixed} alt='write' />
            <div className={styles['card-desc']}>{props.stepDesc[1]}</div>
          </div>
          <div className={styles['card']}>
            <Img fixed={data.review.childImageSharp.fixed} alt='review' />
            <div className={styles['card-desc']}>{props.stepDesc[2]}</div>
          </div>
        </div>

        <Img
          style={{
            position: 'absolute',
            top: '11.3125rem',
            right: '2rem',
          }}
          fixed={data.bg.childImageSharp.fixed}
          alt='stepBg'
        />
        <Img
          style={{
            position: 'absolute',
            top: '31.5rem',
            left: '2.375rem',
          }}
          fixed={data.bg.childImageSharp.fixed}
          alt='stepBg'
        />
      </div>
    </div>
  )
}
