import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './section_title.module.scss'

const SectionTitle = ({title, engTitle, colorStyle}) => {
    const data = useStaticQuery(graphql`
        query {
            line: file(relativePath: { eq: "title_line_ms.png" }) {
                childImageSharp {
                    fluid(maxWidth: 88, maxHeight: 8) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            whiLine: file(relativePath: { eq: "title_line_whi.png" }) {
                childImageSharp {
                    fluid(maxWidth: 88, maxHeight: 8) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <div className={styles['section-title']}>
            <div className={`${styles['eng']} ${colorStyle ? styles['whi-eng'] : '' }`}>{engTitle}</div>
            <div className={`${styles['title']} ${colorStyle ? styles['whi-title'] : '' }`}>{title}</div>
            <Img style={{ position: 'absolute' }}
                 className={styles['line']}
                 fluid={colorStyle ? data.whiLine.childImageSharp.fluid : data.line.childImageSharp.fluid}
                 alt='line' />
        </div>
    )
}

export default SectionTitle
